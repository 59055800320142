import { Component, Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import GroupSelect from "../component/sidebar/group-select";
import Pagination from "../component/sidebar/pagination";
import Rating from "../component/sidebar/rating";
import SkillSelect from "../component/sidebar/skill-select";
import EnrollNow from './enroll-now';
import GoogleAuth from '../page/google-login';
import CourseSingle from '../page/course-single';

const courseList = [
    {
        imgUrl: 'assets/images/course/azure.png',
        imgAlt: 'Azure Administration',
        reviewCount: '03 reviews',
        totalLessons: '18x Lesson',
        schedule: 'Online Class',
        authorImgUrl: 'assets/images/trainer/atin-image-thumbnail.png',
        authorImgAlt: 'course author Atin Gupta',
        authorName: 'Atin Gupta',
        title: 'Azure Cloud',
        price: '$0',
		cate: 'Azure',
        btnText: 'Enroll Now',
        courseSingle: '/course-azure-admin',
    },
    {
        imgUrl: 'assets/images/course/azure-devops.png',
        imgAlt: 'Azure DevOps',
        cate: 'Azure',
        reviewCount: '03 reviews',
        totalLessons: '18x Lesson',
        schedule: 'Online Class',
        authorImgUrl: 'assets/images/trainer/atin-image-thumbnail.png',
        authorImgAlt: 'course author Atin Gupta',
        authorName: 'Atin Gupta',
        title: 'Azure DevOps',
        price: '$100',
        courseTitle: 'Azure DevOps',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
        courseSingle: '#',
    }
    ,
    {
        imgUrl: 'assets/images/course/data-engineering.png',
        imgAlt: 'Azure Data Engineer',
        cate: 'Azure',
        reviewCount: '03 reviews',
        totalLessons: '18x Lesson',
        schedule: 'Online Class',
        authorImgUrl: 'assets/images/trainer/atin-image-thumbnail.png',
        authorImgAlt: 'course author Atin Gupta',
        authorName: 'Atin Gupta',
        title: 'Azure Data Engineer',
        price: '$100',
        courseTitle: 'Azure Data Engineer',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
        courseSingle: '#',
    }
    ,
    {
        imgUrl: 'assets/images/course/azure-architect.png',
        imgAlt: 'Azure Architect',
        cate: 'Azure',
        reviewCount: '03 reviews',
        totalLessons: '18x Lesson',
        schedule: 'Online Class',
        authorImgUrl: 'assets/images/trainer/atin-image-thumbnail.png',
        authorImgAlt: 'course author Atin Gupta',
        authorName: 'Atin Gupta',
        title: 'Azure Architect',
        price: '$100',
        courseTitle: 'Azure Architect',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
        courseSingle: '#',
    }
    ,
    {
        imgUrl: 'assets/images/course/azure-security.png',
        imgAlt: 'Azure Security',
        cate: 'Azure',
        reviewCount: '03 reviews',
        totalLessons: '18x Lesson',
        schedule: 'Online Class',
        authorImgUrl: 'assets/images/trainer/atin-image-thumbnail.png',
        authorImgAlt: 'course author Atin Gupta',
        authorName: 'Atin Gupta',
        title: 'Azure Security',
        price: '$100',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
        courseSingle: '#',
    },
    {
        imgUrl: 'assets/images/course/azure-machine-learning.png',
        imgAlt: 'Azure Machine Learning',
        cate: 'Azure',
        reviewCount: '03 reviews',
        totalLessons: '18x Lesson',
        schedule: 'Online Class',
        authorImgUrl: 'assets/images/trainer/atin-image-thumbnail.png',
        authorImgAlt: 'course author Atin Gupta',
        authorName: 'Atin Gupta',
        title: 'Azure Machine Learning',
        price: '$100',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
        courseSingle: '#',
    },
    {
        imgUrl: 'assets/images/course/azure-ai.png',
        imgAlt: 'Azure AI',
        cate: 'Azure',
        reviewCount: '03 reviews',
        totalLessons: '18x Lesson',
        schedule: 'Online Class',
        authorImgUrl: 'assets/images/trainer/atin-image-thumbnail.png',
        authorImgAlt: 'course author Atin Gupta',
        authorName: 'Atin Gupta',
        title: 'Azure AI',
        price: '$100',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
        courseSingle: '#',
    },
    {
        imgUrl: 'assets/images/course/blockchain-business.png',
        imgAlt: 'Blockchain Foundations',
        cate: 'Blockchain',
        reviewCount: '03 reviews',
        totalLessons: '18x Lesson',
        schedule: 'Online Class',
        authorImgUrl: 'assets/images/trainer/atin-image-thumbnail.png',
        authorImgAlt: 'course author Atin Gupta',
        authorName: 'Atin Gupta',
        title: 'Blockchain Business',
        price: '$100',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
        courseSingle: '#',
    },
    {
        imgUrl: 'assets/images/course/blockchain-solution-architect.png',
        imgAlt: 'Blockchain Solution Architect',
        cate: 'Blockchain',
        reviewCount: '03 reviews',
        totalLessons: '18x Lesson',
        schedule: 'Online Class',
        authorImgUrl: 'assets/images/trainer/atin-image-thumbnail.png',
        authorImgAlt: 'course author Atin Gupta',
        authorName: 'Atin Gupta',
        title: 'Blockchain Solution Architect',
        price: '$100',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
        courseSingle: '#',
    },
    {
        imgUrl: 'assets/images/course/blockchain-developer.png',
        imgAlt: 'Blockchain  Developer',
        cate: 'Blockchain',
        reviewCount: '03 reviews',
        totalLessons: '18x Lesson',
        schedule: 'Online Class',
        authorImgUrl: 'assets/images/trainer/atin-image-thumbnail.png',
        authorImgAlt: 'course author Atin Gupta',
        authorName: 'Atin Gupta',
        title: 'Blockchain Developer',
        price: '$100',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
        courseSingle: '#',
    },
    {
        imgUrl: 'assets/images/course/terraform.png',
        imgAlt: 'Terraform',
        cate: 'DevOps',
        reviewCount: '03 reviews',
        totalLessons: '18x Lesson',
        schedule: 'Online Class',
        authorImgUrl: 'assets/images/trainer/atin-image-thumbnail.png',
        authorImgAlt: 'course author Atin Gupta',
        authorName: 'Atin Gupta',
        title: 'Terraform',
        price: '$100',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
        courseSingle: '#',
    },
    {
        imgUrl: 'assets/images/course/python.png',
        imgAlt: 'Python',
        cate: 'Scripting',
        reviewCount: '03 reviews',
        totalLessons: '18x Lesson',
        schedule: 'Online Class',
        authorImgUrl: 'assets/images/trainer/atin-image-thumbnail.png',
        authorImgAlt: 'course author Atin Gupta',
        authorName: 'Atin Gupta',
        title: 'Python',
        price: '$100',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
        courseSingle: '#',
    },
    {
        imgUrl: 'assets/images/course/machine-learning.png',
        imgAlt: 'Machine Learning',
        cate: 'Data Science',
        reviewCount: '03 reviews',
        totalLessons: '18x Lesson',
        schedule: 'Online Class',
        authorImgUrl: 'assets/images/trainer/atin-image-thumbnail.png',
        authorImgAlt: 'course author Atin Gupta',
        authorName: 'Atin Gupta',
        title: 'Machine Learning',
        price: '$100',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
        courseSingle: '#',
    }
    ,
    {
        imgUrl: 'assets/images/course/R-Programming.png',
        imgAlt: 'R Programming',
        cate: 'Scripting',
        reviewCount: '03 reviews',
        totalLessons: '18x Lesson',
        schedule: 'Online Class',
        authorImgUrl: 'assets/images/trainer/atin-image-thumbnail.png',
        authorImgAlt: 'course author Atin Gupta',
        authorName: 'Atin Gupta',
        title: 'R Programming',
        price: '$100',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
        courseSingle: '#',
    }
    ,
    {
        imgUrl: 'assets/images/course/data-science.png',
        imgAlt: 'Data Science',
        cate: 'Data Science',
        reviewCount: '03 reviews',
        totalLessons: '18x Lesson',
        schedule: 'Online Class',
        authorImgUrl: 'assets/images/trainer/atin-image-thumbnail.png',
        authorImgAlt: 'course author Atin Gupta',
        authorName: 'Atin Gupta',
        title: 'Data Science',
        price: '$100',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
        courseSingle: '#',
    }
    ,
    {
        imgUrl: 'assets/images/course/big-data-hadoop.png',
        imgAlt: 'Big Data and Hadoop',
        cate: 'Data Science',
        reviewCount: '03 reviews',
        totalLessons: '18x Lesson',
        schedule: 'Online Class',
        authorImgUrl: 'assets/images/trainer/atin-image-thumbnail.png',
        authorImgAlt: 'course author Atin Gupta',
        authorName: 'Atin Gupta',
        title: 'Big Data and Hadoop',
        price: '$100',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
        courseSingle: '#',
    }
    ,
    {
        imgUrl: 'assets/images/course/git.png',
        imgAlt: 'GIT',
        cate: 'DevOps',
        reviewCount: '03 reviews',
        totalLessons: '18x Lesson',
        schedule: 'Online Class',
        authorImgUrl: 'assets/images/trainer/atin-image-thumbnail.png',
        authorImgAlt: 'course author Atin Gupta',
        authorName: 'Atin Gupta',
        title: 'GIT',
        price: '$100',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
        courseSingle: '#',
    }
    ,
    {
        imgUrl: 'assets/images/course/apache-spark.png',
        imgAlt: 'Apache Spark / PySpark',
        cate: 'Data Science',
        reviewCount: '03 reviews',
        totalLessons: '18x Lesson',
        schedule: 'Online Class',
        authorImgUrl: 'assets/images/trainer/atin-image-thumbnail.png',
        authorImgAlt: 'course author Atin Gupta',
        authorName: 'Atin Gupta',
        title: 'Apache Spark / PySpark',
        price: '$100',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
        courseSingle: '#',
    }
    ,
    {
        imgUrl: 'assets/images/course/scala.png',
        imgAlt: 'Scala',
        cate: 'Scripting',
        reviewCount: '03 reviews',
        totalLessons: '18x Lesson',
        schedule: 'Online Class',
        authorImgUrl: 'assets/images/trainer/atin-image-thumbnail.png',
        authorImgAlt: 'course author Atin Gupta',
        authorName: 'Atin Gupta',
        title: 'Scala',
        price: '$100',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
        courseSingle: '#',
    }
    ,
    {
        imgUrl: 'assets/images/course/databricks.png',
        imgAlt: 'Data Bricks',
        cate: 'Data Science',
        reviewCount: '03 reviews',
        totalLessons: '18x Lesson',
        schedule: 'Online Class',
        authorImgUrl: 'assets/images/trainer/atin-image-thumbnail.png',
        authorImgAlt: 'course author Atin Gupta',
        authorName: 'Atin Gupta',
        title: 'Data Bricks',
        price: '$100',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
        courseSingle: '#',
    }
    ,
    {
        imgUrl: 'assets/images/course/docker.png',
        imgAlt: 'Docker',
        cate: 'DevOps',
        reviewCount: '03 reviews',
        totalLessons: '18x Lesson',
        schedule: 'Online Class',
        authorImgUrl: 'assets/images/trainer/atin-image-thumbnail.png',
        authorImgAlt: 'course author Atin Gupta',
        authorName: 'Atin Gupta',
        title: 'Docker',
        price: '$100',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
        courseSingle: '#',
    },
    {
        imgUrl: 'assets/images/course/kubernetes.png',
        imgAlt: 'Kubernetes',
        cate: 'DevOps',
        reviewCount: '03 reviews',
        totalLessons: '18x Lesson',
        schedule: 'Online Class',
        authorImgUrl: 'assets/images/trainer/atin-image-thumbnail.png',
        authorImgAlt: 'course author Atin Gupta',
        authorName: 'Atin Gupta',
        title: 'Kubernetes',
        price: '$100',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
        courseSingle: '#',
    },
    {
        imgUrl: 'assets/images/course/artifactory.png',
        imgAlt: 'Artifactory',
        cate: 'DevOps',
        reviewCount: '03 reviews',
        totalLessons: '18x Lesson',
        schedule: 'Online Class',
        authorImgUrl: 'assets/images/trainer/atin-image-thumbnail.png',
        authorImgAlt: 'course author Atin Gupta',
        authorName: 'Atin Gupta',
        title: 'Artifactory',
        price: '$100',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
        courseSingle: '#',
    },
    {
        imgUrl: 'assets/images/course/chef.png',
        imgAlt: 'Chef',
        cate: 'DevOps',
        reviewCount: '03 reviews',
        totalLessons: '18x Lesson',
        schedule: 'Online Class',
        authorImgUrl: 'assets/images/trainer/atin-image-thumbnail.png',
        authorImgAlt: 'course author Atin Gupta',
        authorName: 'Atin Gupta',
        title: 'Chef',
        price: '$100',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
        courseSingle: '#',
    },
    {
        imgUrl: 'assets/images/course/puppet.png',
        imgAlt: 'Puppet',
        cate: 'DevOps',
        reviewCount: '03 reviews',
        totalLessons: '18x Lesson',
        schedule: 'Online Class',
        authorImgUrl: 'assets/images/trainer/atin-image-thumbnail.png',
        authorImgAlt: 'course author Atin Gupta',
        authorName: 'Atin Gupta',
        title: 'Puppet',
        price: '$100',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
        courseSingle: '#',
    },
    {
        imgUrl: 'assets/images/course/ansible.png',
        imgAlt: 'Ansible',
        cate: 'DevOps',
        reviewCount: '03 reviews',
        totalLessons: '18x Lesson',
        schedule: 'Online Class',
        authorImgUrl: 'assets/images/trainer/atin-image-thumbnail.png',
        authorImgAlt: 'course author Atin Gupta',
        authorName: 'Atin Gupta',
        title: 'Ansible',
        price: '$100',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
        courseSingle: '#',
    },
    {
        imgUrl: 'assets/images/course/jenkins.png',
        imgAlt: 'Jenkins',
        cate: 'DevOps',
        reviewCount: '03 reviews',
        totalLessons: '18x Lesson',
        schedule: 'Online Class',
        authorImgUrl: 'assets/images/trainer/atin-image-thumbnail.png',
        authorImgAlt: 'course author Atin Gupta',
        authorName: 'Atin Gupta',
        title: 'Jenkins',
        price: '$100',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
        courseSingle: '#',
    },
    {
        imgUrl: 'assets/images/course/neo4j.png',
        imgAlt: 'Neo4J',
        cate: 'Data Engineering',
        reviewCount: '03 reviews',
        totalLessons: '18x Lesson',
        schedule: 'Online Class',
        authorImgUrl: 'assets/images/trainer/atin-image-thumbnail.png',
        authorImgAlt: 'course author Atin Gupta',
        authorName: 'Atin Gupta',
        title: 'Neo4J',
        price: '$100',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
        courseSingle: '#',
    },
    {
        imgUrl: 'assets/images/course/cosmosdb.png',
        imgAlt: 'CosmosDB',
        cate: 'Data Engineering',
        reviewCount: '03 reviews',
        totalLessons: '18x Lesson',
        schedule: 'Online Class',
        authorImgUrl: 'assets/images/trainer/atin-image-thumbnail.png',
        authorImgAlt: 'course author Atin Gupta',
        authorName: 'Atin Gupta',
        title: 'CosmosDB',
        price: '$100',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
        courseSingle: '#',
    },
    {
        imgUrl: 'assets/images/course/cassandra.png',
        imgAlt: 'Apache Cassandra',
        cate: 'Data Engineering',
        reviewCount: '03 reviews',
        totalLessons: '18x Lesson',
        schedule: 'Online Class',
        authorImgUrl: 'assets/images/trainer/atin-image-thumbnail.png',
        authorImgAlt: 'course author Atin Gupta',
        authorName: 'Atin Gupta',
        title: 'Apache Cassandra',
        price: '$100',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
        courseSingle: '#',
    },
    {
        imgUrl: 'assets/images/course/airflow.png',
        imgAlt: 'Airflow',
        cate: 'Data Engineering',
        reviewCount: '03 reviews',
        totalLessons: '18x Lesson',
        schedule: 'Online Class',
        authorImgUrl: 'assets/images/trainer/atin-image-thumbnail.png',
        authorImgAlt: 'course author Atin Gupta',
        authorName: 'Atin Gupta',
        title: 'Airflow',
        price: '$100',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
        courseSingle: '#',
    },
    {
        imgUrl: 'assets/images/course/azure-data-factory.png',
        imgAlt: 'Azure Data Factory',
        cate: 'Data Engineering',
        reviewCount: '03 reviews',
        totalLessons: '18x Lesson',
        schedule: 'Online Class',
        authorImgUrl: 'assets/images/trainer/atin-image-thumbnail.png',
        authorImgAlt: 'course author Atin Gupta',
        authorName: 'Atin Gupta',
        title: 'Azure Data Factory',
        price: '$100',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
        courseSingle: '#',
    },
    {
        imgUrl: 'assets/images/course/power-bi.png',
        imgAlt: 'Power BI',
        cate: 'Data Engineering',
        reviewCount: '03 reviews',
        totalLessons: '18x Lesson',
        schedule: 'Online Class',
        authorImgUrl: 'assets/images/trainer/atin-image-thumbnail.png',
        authorImgAlt: 'course author Atin Gupta',
        authorName: 'Atin Gupta',
        title: 'Power BI',
        price: '$100',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
        courseSingle: '#',
    },
    {
        imgUrl: 'assets/images/course/groovy-and-grails.png',
        imgAlt: 'Groovy and Grails',
        cate: 'Scripting',
        reviewCount: '03 reviews',
        totalLessons: '18x Lesson',
        schedule: 'Online Class',
        authorImgUrl: 'assets/images/trainer/atin-image-thumbnail.png',
        authorImgAlt: 'course author Atin Gupta',
        authorName: 'Atin Gupta',
        title: 'Groovy and Grails',
        price: '$100',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
        courseSingle: '#',
    },
    {
        imgUrl: 'assets/images/course/apache-nifi.png',
        imgAlt: 'Apache NiFi',
        cate: 'Data Engineering',
        reviewCount: '03 reviews',
        totalLessons: '18x Lesson',
        schedule: 'Online Class',
        authorImgUrl: 'assets/images/trainer/atin-image-thumbnail.png',
        authorImgAlt: 'course author Atin Gupta',
        authorName: 'Atin Gupta',
        title: 'Apache NiFi',
        price: '$100',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
        courseSingle: '#',
    },
    {
        imgUrl: 'assets/images/course/splunk.png',
        imgAlt: 'Splunk',
        cate: 'Monitoring Tools',
        reviewCount: '03 reviews',
        totalLessons: '18x Lesson',
        schedule: 'Online Class',
        authorImgUrl: 'assets/images/trainer/atin-image-thumbnail.png',
        authorImgAlt: 'course author Atin Gupta',
        authorName: 'Atin Gupta',
        title: 'Splunk',
        price: '$100',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
        courseSingle: '#',
    },
    {
        imgUrl: 'assets/images/course/datadog.png',
        imgAlt: 'DataDog',
        cate: 'Monitoring Tools',
        reviewCount: '03 reviews',
        totalLessons: '18x Lesson',
        schedule: 'Online Class',
        authorImgUrl: 'assets/images/trainer/atin-image-thumbnail.png',
        authorImgAlt: 'course author Atin Gupta',
        authorName: 'Atin Gupta',
        title: 'DataDog',
        price: '$100',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
        courseSingle: '#',
    },
    {
        imgUrl: 'assets/images/course/appdynamics.png',
        imgAlt: 'AppDynamics',
        cate: 'Monitoring Tools',
        reviewCount: '03 reviews',
        totalLessons: '18x Lesson',
        schedule: 'Online Class',
        authorImgUrl: 'assets/images/trainer/atin-image-thumbnail.png',
        authorImgAlt: 'course author Atin Gupta',
        authorName: 'Atin Gupta',
        title: 'AppDynamics',
        price: '$100',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
        courseSingle: '#',
    },
    
    
    
]


const CoursePage = (props) => {
    const [showApply, setShowApply] = useState(true);
    const [searchText, setSearchText] = useState("");
    const [courseListFiltered, setCourseListFiltered] = useState([]);
    
    const searchOnChange = (event) => {
        //console.log("called onChange");
        //console.log(event.target.value);
        setSearchText(event.target.value.toLowerCase());
    }

    useEffect(() => {
        //do operation on state change
        const courseList_filtered = courseList.filter(val => val.title.toLowerCase().includes(searchText) || val.authorName.toLowerCase().includes(searchText)).filter(val => val.title.length > 0);
        setCourseListFiltered(courseList_filtered);
    },[searchText])


    return (
        <Fragment>
            <Header currentUser={props.currentUser} setCurrentUser={props.setCurrentUser} userName={props.userName} setUserName={props.setUserName} email={props.email} setEmail={props.setEmail} isSignedIn={props.isSignedIn} setIsSignedIn={props.setIsSignedIn} />
            
            <div className="course-section section-bg">
                <div className="container">
                    
                    <div className="row justify-content-center">
                    <div className="course-search">
                        <input className="form-control input" type="text" name="searchText" placeholder="Search" onChange={searchOnChange} />
                        Showing {courseListFiltered.length} of {courseList.length} results
                    </div>
                    </div>
                    


                    <div className="section-wrapper">
                        <div className="course-showing-part">
                            <div className="d-flex flex-wrap align-items-center justify-content-between">
                                <div className="course-showing-part-right d-flex flex-wrap align-items-center">
                                </div>
                            </div>
                        </div>
                        <div className="row g-4 justify-content-center row-cols-xl-2 row-cols-md-2 row-cols-1">
                            {courseListFiltered.map((val, i) => (
                                <div className="col" key={i}>
                                    <div className="course-item">
                                        <div className="course-inner">
                                            <div className="course-thumb">
                                                <a className="course-image" href={`${val.courseSingle}`}><img className="course-image" src={`${val.imgUrl}`} alt={`${val.imgAlt}`} /></a>
                                            </div>
                                            <div className="course-content">
                                                <div className="course-price">{val.price}</div>
                                                <h4>
                                                    {val.title}
                                                </h4>
                                                <div className="course-footer">
                                                    <div className="course-author">
                                                        <img className="author-image" src={`${val.authorImgUrl}`} alt={`${val.authorImgAlt}`} />
                                                        <a target="_blank" href="https://www.atingupta.in" className="ca-name">{val.authorName}</a>
                                                    </div>
                                                    <div className="course-btn">
                                                        {props.isSignedIn ? (
                                                            <EnrollNow userName={props.userName} email={props.email} title={val.title}/>
                                                        ) : (<a href="#" className="lab-btn"><GoogleAuth btn_label="Enroll Now" currentUser={props.currentUser} setCurrentUser={props.setCurrentUser} userName={props.userName} setUserName={props.setUserName} email={props.email} setEmail={props.setEmail} isSignedIn={props.isSignedIn} setIsSignedIn={props.setIsSignedIn} /></a>) }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <Pagination />
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}

export default CoursePage;