import React, { useState, useEffect } from "react";
import { useRef } from "react";
import { useForm } from "react-hook-form";
import ReCAPTCHA from 'react-google-recaptcha';
import axios from "axios";

const subTitle = "100% Scholarship";
const title = <h2 className="title fw-normal text-white">Join Workshop for <span className="fw-bold"></span> <span className="theme-color fw-bold">Azure</span> <span className="fw-bold">Cloud</span></h2>;

const desc = "Limited Time Offer! Hurry Up";
const regTitle = "Register Now";
const btnText = "Register Now";

const Register = (props) => {
    const [successMessage, setSuccessMessage] = useState("");
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const [userName, setUserName] = useState("");
    const [email, setEmail] = useState("");

    const recaptchaRef = useRef(null);
    
    
    useEffect(() => {
    if (props.currentUser && props.currentUser.getBasicProfile())
    {
        setUserName(props.currentUser.getBasicProfile().getName())
        setEmail(props.currentUser.getBasicProfile().getEmail())
    }
    else {
        setUserName("")
        setEmail("")
    }
    });

    const onSubmit = (data, r) => {
        //console.log("Starting onSubmit");
        //console.log(recaptchaRef);
        //console.log(data);
        //console.log(r);

        const recaptchaValue = recaptchaRef.current.value;
        
        //console.log("recaptchaValue: ", recaptchaValue);

        sendEmail(
        {
            name: data.name,
            phone: data.phone,
            email: data.email,
            subject: 'Request for Azure Workshop',
            description: data.description,
            form_name: "register-for-workshop",
            'g-recaptcha-response': recaptchaValue,
        },
        )
        r.target.reset();
    }

    const sendEmail = (variables) => {
        //console.log("Sending email")
        const baseURL = `${process.env.REACT_APP_BASE_URL}/lead-generation/sendemailcommon/`

        axios.post(baseURL, variables).then((response) => {
            //console.log(response.data);
            setSuccessMessage(response.data);
        });
        
    }

    const onChange = (value) => {
        recaptchaRef.current.value = value;
        //console.log('Captcha value:', value);
        //console.log('recaptchaRef:', recaptchaRef);
    }

    return (
        <section className="register-section padding-tb-less pb-0">
            <div className="container">
                <div className="row g-4 row-cols-lg-2 row-cols-1 align-items-center">
                    <div className="col">
                        <div className="section-header">
                            <span className="subtitle">{subTitle}</span>
                            {title}
                            <p>{desc}</p>
                        </div>
                    </div>
                    <div className="col">
                        <div className="section-wrapper">
                            <span className="success-message">{successMessage}</span>
                            <h4>{regTitle}</h4>
                            <form onSubmit={handleSubmit(onSubmit)} className="register-form">
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Username"
                                    defaultValue={userName}
                                    {...register('name')}
                                    className="reg-input"
                                />
                                <input
                                    type="email"
                                    name="email"
                                    defaultValue={email}
                                    placeholder="Email"
                                    {...register('email')}
                                    className="reg-input"
                                />
                                <input
                                    type="text"
                                    name="phone"
                                    placeholder="Phone"
                                    {...register('phone')}
                                    className="reg-input"
                                />
                                <input
                                    type="textarea"
                                    name="description"
                                    placeholder="Description"
                                    {...register('description')}
                                    className="reg-input"
                                />
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey="6LecE1giAAAAAApN2obCkit1Soc8jGIF960Sgwqh"
                                    onChange={onChange}
                                />
                                <button className="lab-btn"><span>{btnText}</span></button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Register;