import { Component, Fragment, useState, useRef, useEffect } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import { useForm } from "react-hook-form";
import PageHeader from "../component/layout/pageheader";
import GoogleMap from "../component/sidebar/googlemap";
import ReCAPTCHA from 'react-google-recaptcha';
import axios from "axios";




const subTitle = "Get in touch with us";
const title = "We're Always Eager To Hear From You!";
const conSubTitle = "Get in touch with Contact us";
const conTitle = "Fill The Form Below So We Can Get To Know You And Your Needs Better.";
const btnText = "Send Message";


const contactList = [
    {
        imgUrl: 'assets/images/icon/01.png',
        imgAlt: 'contact icon',
        title: 'Office Address',
        desc: 'Delhi NCR',
    },
    {
        imgUrl: 'assets/images/icon/02.png',
        imgAlt: 'contact icon',
        title: 'Phone number',
        desc: '+919810707414',
    },
    {
        imgUrl: 'assets/images/icon/03.png',
        imgAlt: 'contact icon',
        title: 'Send email',
        desc: 'atingupta2005@gmail.com',
    },
    {
        imgUrl: 'assets/images/icon/04.png',
        imgAlt: 'contact icon',
        title: 'Our website',
        desc: 'www.atttrainings.com',
    },
]


const ContactPage = (props) => {
    const [successMessage, setSuccessMessage] = useState("");
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const [userName, setUserName] = useState("");
    const [email, setEmail] = useState("");

    useEffect(() => {
    if (props.currentUser && props.currentUser.getBasicProfile())
    {
        setUserName(props.currentUser.getBasicProfile().getName())
        setEmail(props.currentUser.getBasicProfile().getEmail())
    }
    else {
        setUserName("")
        setEmail("")
    }
    });

    const recaptchaRef = useRef(null);
    
    const onSubmit = (data, r) => {
        //console.log("Starting onSubmit");
        //console.log(recaptchaRef);
        //console.log(data);
        //console.log(r);

        const recaptchaValue = recaptchaRef.current.value;
        
        //console.log(recaptchaValue);

        sendEmail(
        {
            name: data.name,
            phone: data.phone,
            email: data.email,
            subject: data.subject,
            description: data.description,
            form_name: "contact-us",
            'g-recaptcha-response': recaptchaValue,
        },
        )
        r.target.reset();
    }

    const sendEmail = (variables) => {
        //console.log("Sending email")
        
        const baseURL = `${process.env.REACT_APP_BASE_URL}/lead-generation/sendemailcommon/`

        axios.post(baseURL, variables).then((response) => {
            //console.log(response.data);
            setSuccessMessage(response.data);
        });
        
    }

    const onChange = (value) => {
        recaptchaRef.current.value = value;
        //console.log('Captcha value:', value);
        //console.log('recaptchaRef:', recaptchaRef);
    }
    return ( 
        <Fragment>
            <Header currentUser={props.currentUser} setCurrentUser={props.setCurrentUser} userName={props.userName} setUserName={props.setUserName} email={props.email} setEmail={props.setEmail} isSignedIn={props.isSignedIn} setIsSignedIn={props.setIsSignedIn}  />
            <div className="map-address-section">
                <div className="container">
                    <div className="section-wrapper">
                        <div className="row flex-row-reverse">
                            <div className="col-xl-4 col-lg-5 col-12">
                                <div className="contact-wrapper padding-tb-less">
                                    {contactList.map((val, i) => (
                                        <div className="contact-item" key={i}>
                                            <div className="contact-thumb">
                                                <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                            </div>
                                            <div className="contact-content">
                                                <h6 className="title">{val.title}</h6>
                                                <p>{val.desc}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
        <div className="col-xl-8 col-lg-7 col-12">
            <div className="contact-section padding-tb-less">
                <div className="container">
                    <div className="section-wrapper">
                        <span className="success-message">{successMessage}</span>
                        <form className="contact-form" onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-group">
                                <span className="error-message">
                                    {errors.name && errors.name.message}
                                </span>
                                <input
                                    type="text"
                                    name="name"
                                    defaultValue={userName}
                                    placeholder="Your Name *"
                                    {
                                        ...register("name", {
                                        required: "Please enter your name",
                                        maxLength: 50,
                                        minLength: 3
                                        }
                                        )
                                    }
                                    aria-invalid={errors.name ? "true" : "false"} 
                                />
                            </div>

                            <span className="error-message">
                                {errors.email && errors.email.message}
                            </span>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="email"
                                    defaultValue={email}
                                    placeholder="Your Email *"
                                    {
                                    ...register("email", {
                                    required: "Please provide you email",
                                    minLength: 10,
                                    maxLength: 100,
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "invalid Email"
                                            }
                                        }
                                    )
                                    }
                                    aria-invalid={errors.email ? "true" : "false"} 
                                />
                            </div>

                            <span className="error-message">
                                {errors.phone && errors.phone.message}
                            </span>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="phone"
                                    placeholder="Mobile Number *"
                                    {
                                        ...register("phone", {
                                        required: "Please enter correct mobile number",
                                        maxLength: 15,
                                        minLength: 10
                                        }
                                        )
                                    }
                                    aria-invalid={errors.name ? "true" : "false"} 
                                />
                            </div>

                            <span className="error-message">
                                {errors.subject && errors.subject.message}
                            </span>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="subject"
                                    placeholder="Your Subject *"
                                    {
                                        ...register("subject", {
                                        required: "Please enter your subject",
                                        maxLength: 50,
                                        minLength: 3
                                        }
                                        )
                                    }
                                    aria-invalid={errors.subject ? "true" : "false"} 
                                />
                            </div>

                            <span className="error-message">
                                {errors.description && errors.description.message}
                            </span>
                            <div className="c w-100">
                                <textarea 
                                    rows="4" 
                                    type="text"
                                    name="description"
                                    placeholder="Your Message"
                                    {
                                        ...register("description", {
                                        required: "Please enter your description",
                                        maxLength: 500,
                                        minLength: 3
                                        }
                                        )
                                    }
                                    aria-invalid={errors.description ? "true" : "false"} 
                                ></textarea>
                            </div>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey="6LecE1giAAAAAApN2obCkit1Soc8jGIF960Sgwqh"
                                onChange={onChange}
                            />
                            <div className="form-group w-100 text-center">
                                <button className="lab-btn"><span>{btnText}</span></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </Fragment>
    );
}



export default ContactPage;