const name = "Atin Gupta"
const degi = "Corporate Trainer"
const desc = "Providing support, consultancy and certification trainings on Azure, AWS, DevOps, Blockchain, Neo4J, Spark, Kubenetes, Data Bricks, Docker, Terraform, Power BI, Chef, Puppet, Ansible, Jenkins, Jfrog, Airflow etc."


const socialList = [
    {
        link: '#',
        iconName: 'icofont-facebook',
        className: 'facebook',
    },
    {
        link: 'https://twitter.com/atingupta2005/',
        iconName: 'icofont-twitter',
        className: 'twitter',
    },
    {
        link: 'https://www.linkedin.com/in/profileatingupta/',
        iconName: 'icofont-linkedin',
        className: 'linkedin',
    },
]

const Author = () => {
    return (
        <div className="authors">
            <div className="author-thumb">
                <img src="assets/images/trainer/atin-image.png" alt="atingupta2005" />
            </div>
            <div className="author-content">
                <h5>{name}</h5>
                <span>{degi}</span>
                <p>{desc}</p>
                <ul className="lab-ul social-icons">
                    {socialList.map((val, i) => (
                        <li key={i}>
                            <a href={val.link} className={val.className}><i className={val.iconName}></i></a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}
 
export default Author;