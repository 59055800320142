import { Component, Fragment, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { Flasher, flash } from "react-universal-flash";
import Message from "./Message";

const ApplyForTraining = (props) => {
    const [successMessage, setSuccessMessage] = useState("");
    const [showSign, setShowSign] = useState(false);

    const [applyButtonText, setApplyButtonText] = useState("Apply");
    const [showApply, setShowApply] = useState(true);

    
    //const [buttonStatus, setSuccessMessage] = useState("");
    
    const hideDiv = (value) => {
        setSuccessMessage("")
        setShowSign(false);
    }


    const onClick = (value) => {
        //console.log('Apply Click:', value);
        //console.log('Apply Click - props:', props);
        //console.log(window.gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getEmail())
        setSuccessMessage("Working. Please wait.... ")
        setShowSign(true);
        const email_id = window.gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getEmail()
        //console.log(email_id)

        const baseURL = `${process.env.REACT_APP_BASE_URL}/lead-generation/lead/send_training_details_to_trainer/${email_id}/${props.lead_id}`;

        axios.get(baseURL).then((response) => {
            //console.log(response.data);
            if (response.data.success == 1)
            {
                setSuccessMessage("Applied successfully! Our vendor will contact you soon");
                setShowSign(true);
                flash("Applied successfully! We will contact you soon",2000, "green")
                setApplyButtonText("Applied");
                setShowApply(false); 
                //console.log(successMessage)
            }
            else {
                setSuccessMessage(response.data.message);
                setShowSign(true);
                flash(response.data.message,2000, "red")
                setShowApply(true);
                //console.log(successMessage)
            }
        });
    }

    return ( 
        <Fragment>
            { showApply ? <button className="training-apply-btn" onClick={onClick}><span>{applyButtonText}</span></button> : null }
            <div onClick={hideDiv} className="trainer_applied">{successMessage}
                <span className={(showSign ? 'crossSignShow' : 'crossSignHide')}><span>&#10007;</span></span>
            </div>
            

        </Fragment>
    );
}



export default ApplyForTraining;