import {BrowserRouter, Routes, Route } from "react-router-dom";
import 'swiper/css';
import { Component, Fragment, useState, useEffect } from "react";

import ScrollToTop from "./component/layout/ScrollToTop";
import ErrorPage from "./page/404";
import AboutPage from "./page/about";
import BlogPage from "./page/blog";
import BlogPageTwo from "./page/blog-2";
import BlogPageThree from "./page/blog-3";
import BlogSingle from "./page/blog-single";
import CartPage from "./page/cart-page";
import ContactPage from "./page/contact";
import TrainerContactPage from "./page/trainer-contact";
import TrainingRequirementsPage from "./page/training-requirements";
import CoursePage from "./page/course";
import CourseSingle from "./page/course-single";
import CourseAzureAdmin from "./page/course-azure-admin";
import CourseEnroll from "./page/course-enroll";
import PostRequirementPage from "./page/post-requirement";
import ForgetPass from "./page/forgetpass";
import Home from "./page/home";
import HomeTwo from "./page/home-2";
import HomeThree from "./page/home-3";
import HomeFour from "./page/home-4";
import HomeFive from "./page/home-5";
import HomeSix from "./page/home-6";
import HomeSeven from "./page/home-7";
import InstructorPage from "./page/instructor";
import GoogleAuth from "./page/google-login";
import SearchNone from "./page/search-none";
import SearchPage from "./page/search-page";
import ShopPage from "./page/shop";
import ShopDetails from "./page/shop-single";
import SignupPage from "./page/signup";
import TeamPage from "./page/team";
import TeamSingle from "./page/team-single";

function App() {
   const [userName, setUserName] = useState("");
			const [email, setEmail] = useState("");
   const [isSignedIn, setIsSignedIn] = useState(null);
			const [currentUser, setCurrentUser] = useState(null);

		//console.log("app.js: (userName, email, isSignedIn)", userName, email, isSignedIn)
		
	return (
		<BrowserRouter>
			<ScrollToTop />
			<Routes>
				<Route path="/" element={<HomeFive currentUser={currentUser} setCurrentUser={setCurrentUser} userName={userName} setUserName={setUserName} email={email} setEmail={setEmail} isSignedIn={isSignedIn} setIsSignedIn={setIsSignedIn} />} />
				<Route path="index" element={<HomeFive currentUser={currentUser} setCurrentUser={setCurrentUser} userName={userName} setUserName={setUserName} email={email} setEmail={setEmail} isSignedIn={isSignedIn} setIsSignedIn={setIsSignedIn}  />} />
				<Route path="course" element={<CoursePage currentUser={currentUser} setCurrentUser={setCurrentUser} userName={userName} setUserName={setUserName} email={email} setEmail={setEmail} isSignedIn={isSignedIn} setIsSignedIn={setIsSignedIn} />} />
				<Route path="course-single" element={<CourseSingle />} />
				<Route path="course-azure-admin" element={<CourseAzureAdmin />} />
				<Route path="course-enroll" element={<CourseEnroll />} />
				<Route path="post-requirement" element={<PostRequirementPage currentUser={currentUser} setCurrentUser={setCurrentUser} userName={userName} setUserName={setUserName} email={email} setEmail={setEmail} isSignedIn={isSignedIn} setIsSignedIn={setIsSignedIn} />} />
				<Route path="blog" element={<BlogPage currentUser={currentUser} setCurrentUser={setCurrentUser} userName={userName} setUserName={setUserName} email={email} setEmail={setEmail} isSignedIn={isSignedIn} setIsSignedIn={setIsSignedIn} />} />
				<Route path="blog-2" element={<BlogPageTwo />} />
				<Route path="blog-3" element={<BlogPageThree />} />
				<Route path="blog-single" element={<BlogSingle />} />
				<Route path="about" element={<AboutPage />} />
				<Route path="team" element={<TeamPage />} />
				<Route path="team-single" element={<TeamSingle />} />
				<Route path="instructor" element={<InstructorPage />} />
				<Route path="shop" element={<ShopPage />} />
				<Route path="shop-single" element={<ShopDetails />} />
				<Route path="cart-page" element={<CartPage />} />
				<Route path="search-page" element={<SearchPage />} />
				<Route path="search-none" element={<SearchNone />} />
				<Route path="contact" element={<ContactPage currentUser={currentUser} setCurrentUser={setCurrentUser} userName={userName} setUserName={setUserName} email={email} setEmail={setEmail} isSignedIn={isSignedIn} setIsSignedIn={setIsSignedIn} />} />
				<Route path="for-trainers" element={<TrainerContactPage />} />
				<Route path="training-requirements" element={<TrainingRequirementsPage currentUser={currentUser} setCurrentUser={setCurrentUser} userName={userName} setUserName={setUserName} email={email} setEmail={setEmail} isSignedIn={isSignedIn} setIsSignedIn={setIsSignedIn} />} />
				<Route path="login" element={<GoogleAuth currentUser={currentUser} setCurrentUser={setCurrentUser} userName={userName} setUserName={setUserName} email={email} setEmail={setEmail} isSignedIn={isSignedIn} setIsSignedIn={setIsSignedIn} />} />
								<Route path="signup" element={<SignupPage />} />
				<Route path="forgetpass" element={<ForgetPass />} />
				<Route path="*" element={<ErrorPage />} />
 		</Routes>
		</BrowserRouter>
	);
}

export default App;
