import { Component, Fragment, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const EnrollNow = (props) => {
    const [successMessage, setSuccessMessage] = useState("");
    const [enrollButtonText, setEnrollButtonText] = useState("Enroll Now");
    const [showEnroll, setShowEnroll] = useState(true);
    let navigate = useNavigate();

    //const [buttonStatus, setSuccessMessage] = useState("");
    

    const onClick = (value) => {
        //console.log('Enroll Click:', value);
        //console.log('Enroll Click - props:', props);
        
        navigate("/course-enroll?title="+ props.title);
    }

    return ( 
        <Fragment>
            { showEnroll ? <a onClick={onClick} href="#" className="lab-enroll-btn-text">{enrollButtonText} <i className="icofont-external-link"></i></a> : ("") }
        </Fragment>
    );
}



export default EnrollNow;