import React from 'react';

class GoogleAuth extends React.Component {
  state = { isSignedIn: null, btn_label: "Sign In" };

  componentDidMount() {
    if (this.props.btn_label)
      this.setState({ btn_label: this.props.btn_label })

    window.gapi.load('client:auth2', () => {
      //console.log(process.env.REACT_APP_PUBLIC_GOOGLE_CLIENT_ID)
      window.gapi.client
        .init({
          clientId:
            process.env.REACT_APP_PUBLIC_GOOGLE_CLIENT_ID,
          scope: 'profile',
          plugin_name: "ATT Trainings",
        })
        .then(() => {
          //console.log("google-login- this.props: ", this.props);
          this.auth = window.gapi.auth2.getAuthInstance();
          this.setState({ isSignedIn: this.auth.isSignedIn.get() });
          //console.log("google-login- this.props: ", this.props)
          this.props.setIsSignedIn(this.auth.isSignedIn.get());
          this.auth.isSignedIn.listen(this.onAuthChange);

          if (this.auth.isSignedIn.get())
          {
            this.props.setIsSignedIn(this.auth.isSignedIn.get());
            this.props.setUserName(this.auth.currentUser.get().getBasicProfile().getName());
            this.props.setEmail(this.auth.currentUser.get().getBasicProfile().getEmail());
            this.props.setCurrentUser(this.auth.currentUser.get());
          }
          else {
            this.props.setIsSignedIn(false);
            this.props.setUserName("");
            this.props.setEmail("");
            this.props.setCurrentUser(null);
          }
          //console.log("after google-login- this.props: ", this.props);
        });
    });
  }

  onAuthChange = () => {
    this.setState({ isSignedIn: this.auth.isSignedIn.get() });
    this.props.setIsSignedIn(this.auth.isSignedIn.get());
    //console.log("onAuthChange this.props: ", this.props)
    if (this.props.isSignedIn)
    {
      this.props.setUserName(this.auth.currentUser.get().getBasicProfile().getName());
      this.props.setEmail(this.auth.currentUser.get().getBasicProfile().getEmail());
      this.props.setCurrentUser(this.auth.currentUser.get());
    }
    else
    {
      this.props.setUserName("");
      this.props.setEmail("");
      this.props.setCurrentUser(null);
    }
  };

  onSignIn = () => {
    this.auth.signIn();
  };

  onSignOut = () => {
    this.auth.signOut();
  };

  renderAuthButton() {
    if (this.state.isSignedIn === null) {
      return null;
    } else if (this.state.isSignedIn) {
      return (
        <div>
          <span>
            <span onClick={this.onSignOut}>
              Sign Out
            </span>
          </span>
        </div>
      );
    } else {
      return (
        <span onClick={this.onSignIn}>
          {this.state.btn_label}
        </span>
      );
    }
  }

  render() {
    return <div>{this.renderAuthButton()}</div>;
  }
}

export default GoogleAuth;
