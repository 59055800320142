import { Component, Fragment, useState, useEffect } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import Axios from "axios";
import { setupCache, buildWebStorage } from 'axios-cache-interceptor';
import React from "react";
import parse from 'html-react-parser';
import ApplyForTraining from './apply-for-training';
import GoogleAuth from '../page/google-login';
import {InlineShareButtons} from 'sharethis-reactjs';
import { useNavigate } from "react-router-dom";

const myStorage = buildWebStorage(sessionStorage, 'axios-cache:');
const axios = setupCache(Axios, { storage: myStorage });

const baseURL = `${process.env.REACT_APP_BASE_URL}/lead-generation/lead/json/`;

const TrainingRequirementsPage = (props) => {
    const [trainings, setTrainings] = React.useState([]);
    const [searchText, setSearchText] = React.useState("");
    let navigate = useNavigate();

    React.useEffect(() => {
        const cache_options = { interpretHeader: true }
        axios.get(baseURL, cache_options).then((response) => {
            setTrainings(response.data.all_data);
        });
    },[]);

    if (!trainings) return null;

    const searchOnChange = (event) => {
        //console.log("called onChange");
        //console.log(event.target.value);
        setSearchText(event.target.value.toLowerCase());
    }
    //console.log("training requirements props: ", props)

    const onClick = (value) => {
        //console.log('Enroll Click:', value);
        //console.log('Enroll Click - props:', props);
        
        navigate("/post-requirement");
    }

    return ( 
        <Fragment>
            <Header currentUser={props.currentUser} setCurrentUser={props.setCurrentUser} userName={props.userName} setUserName={props.setUserName} email={props.email} setEmail={props.setEmail} isSignedIn={props.isSignedIn} setIsSignedIn={props.setIsSignedIn} />
            <div className="map-address-section">
                <div className="container">
                    <div className="section-wrapper">
                        <div className="row flex-row-reverse">
                            <div className="col-xl-4 col-lg-5 col-12">
                                <div className="contact-wrapper padding-tb">
                                </div>
                            </div>
                            <div className="col-xl-8 col-lg-7 col-12">
            <div className="contact-section padding-tb-very-less">
                <div className="container">
                    <div className="section-wrapper">
                    
                    
                    <InlineShareButtons
                            config={{
                                enabled: true, networks: [
                                'whatsapp',
                                'linkedin',
                                'messenger',
                                'facebook',
                                'twitter'
                                ],
                                show_total: true,

                                // OPTIONAL PARAMETERS
                                url: 'https://atttrainings.com/training-requirements', // (defaults to current url)
                            }}
                            />
                    
                    <div className="padding-tb-very-less">
                        <input
                        className="searchbox"
                        type="text"
                        name="searchText"
                        placeholder="Type to search"
                        onChange={searchOnChange} />
                        <button onClick={onClick} className="post-requirement-btn"><span>Post Requirement</span></button>
                    </div>
                    <br />
                
                    
                    {trainings.length<=0 && (<div className="padding-tb"><h3>Loading..................</h3></div>)}

                    {trainings.filter(val => val.topic.toLowerCase().includes(searchText) || val.delivery_location.toLowerCase().includes(searchText)).filter(val => val.topic.length > 0).map((val, i) => (
                                        <div className="contact-item" key={i}>
                                            <div className="contact-thumb">
                                            </div>
                                            <div className="contact-content">
                                                <h6 className="title">{val.posted_on.substring(0, 10)}</h6>
                                                <p>{parse(val.topic)}</p>
                                                <p>{parse(val.delivery_location)}</p>
                                            </div>
                                            {props.isSignedIn ? (
                                                <ApplyForTraining userName={props.userName} email={props.email} lead_id={val.lead_id}/>
                                            ) : (<a href="#" className="training-apply-btn" ><GoogleAuth btn_label="Apply" currentUser={props.currentUser} setCurrentUser={props.setCurrentUser} userName={props.userName} setUserName={props.setUserName} email={props.email} setEmail={props.setEmail} isSignedIn={props.isSignedIn} setIsSignedIn={props.setIsSignedIn} /></a>) }
                                        </div>
                                    ))}

                    </div>
                </div>
            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </Fragment>
    );
}



export default TrainingRequirementsPage;