import { Fragment } from "react";
import { Link } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import Pagination from "../component/sidebar/pagination";

const blogList = [
    {
        imgUrl: 'assets/images/logo/01.png',
        blog_url: 'https://www.linkedin.com/posts/profileatingupta_is-your-team-struggling-with-issues-activity-6947463373785636864-BYUm?utm_source=share&utm_medium=member_desktop',
        title: 'Is your team struggling with issues while development',
        desc: 'Is your team struggling with issues while development or deployment of projects? I can help you to take the projects to the next level and fix the issues? I know more than 40 different technolgies in Cloud, Devops, Blockchain, AI, Machine Learning, Data Engineering, Data Science etc. On the tops of that I can understand any new technonology if required. Connect to me in case you need my support?',
        commentCount: '3',
        btnText: 'Read More',
        metaList: [
            {
                iconName: 'icofont-ui-user',
                text: 'Atin Gupta',
            },
            {
                iconName: 'icofont-calendar',
                text: 'Aug 09,2022',
            },
        ],
    },
    {
        imgUrl: 'assets/images/logo/01.png',
        blog_url: 'https://www.linkedin.com/posts/profileatingupta_need-an-excellent-trainer-activity-6947463807342456832-WIPQ?utm_source=share&utm_medium=member_desktop',
        title: 'Do you need an excellent trainer',
        desc: 'Do you need an excellent trainer who not only have good training delivery experience, but also worked on the real projects? I know 40 different technologies and can help. Lets discuss about your requirements and take it further',
        commentCount: '3',
        btnText: 'Read More',
        metaList: [
            {
                iconName: 'icofont-ui-user',
                text: 'Atin Gupta',
            },
            {
                iconName: 'icofont-calendar',
                text: 'Aug 09,2022',
            },
        ],
    },
    {
        imgUrl: 'assets/images/logo/01.png',
        blog_url: 'https://www.linkedin.com/posts/profileatingupta_facing-difficulty-to-find-the-right-candidates-activity-6947463183863349248-eSoY?utm_source=share&utm_medium=member_desktop',
        title: 'Are you a CEO of a startup and facing difficulty to find the right candidates',
        desc: 'Are you a CEO of a startup and facing difficulty to find the right candidates in the market to hire? To find the skilled employees is one of the most challenging issues for any company. I can definitely help you with that. Please connect.',
        commentCount: '3',
        btnText: 'Read More',
        metaList: [
            {
                iconName: 'icofont-ui-user',
                text: 'Atin Gupta',
            },
            {
                iconName: 'icofont-calendar',
                text: 'Aug 09,2022',
            },
        ],
    },
    {
        imgUrl: 'assets/images/logo/01.png',
        blog_url: 'https://www.linkedin.com/posts/profileatingupta_httpswwwatinguptain-do-you-need-an-activity-6947461301950435328-mzKv?utm_source=share&utm_medium=member_desktop',
        title: 'Do you need an excellent trainer who not only have good training delivery experience',
        desc: 'Do you need an excellent trainer who not only have good training delivery experience, but also worked on the real projects? I know 40 different technologies and can help. Lets discuss about your requirements and take it further',
        commentCount: '3',
        btnText: 'Read More',
        metaList: [
            {
                iconName: 'icofont-ui-user',
                text: 'Atin Gupta',
            },
            {
                iconName: 'icofont-calendar',
                text: 'Aug 09,2022',
            },
        ],
    },
    {
        imgUrl: 'https://media.licdn.com/dms/image/C4D22AQFAZ3ayS1TJyg/feedshare-shrink_800/0/1650545248901?e=1669852800&v=beta&t=g_biQpcL4vfcAW9EbvlE8wc3k_c_dGqamBKnrM0x3eU',
        blog_url: 'https://www.linkedin.com/posts/profileatingupta_training-terraform-activity-6922888546202566656-K87m?utm_source=share&utm_medium=member_desktop',
        title: 'Delivered Terraform Training to "Applied Materials" in Bengaluru',
        desc: 'I am very happy after going out to deliver a classroom training (after almost 2 years) on Terraform to "Applied Materials" in Bengaluru this week. In fact, it was a Hybrid training with few participants in the Classroom and other joined Online. The participants were extremely satisfied and happy. Experiencing and feeling the joy of flying, travelling, talking Face-2-Face was really great. Looking forward for more classroom trainings',
        commentCount: '3',
        btnText: 'Read More',
        metaList: [
            {
                iconName: 'icofont-ui-user',
                text: 'Atin Gupta',
            },
            {
                iconName: 'icofont-calendar',
                text: 'Aug 09,2022',
            },
        ],
    },
    {
        imgUrl: 'https://media.licdn.com/dms/image/C4D22AQFfQmrerMeiXA/feedshare-shrink_1280/0/1613976296602?e=1669852800&v=beta&t=ptB0Xg9KURHGLUY1CQa6sMxkakfyQppMk7gJoymHJtw',
        blog_url: 'https://www.linkedin.com/posts/profileatingupta_httpslnkdind9-smne-terraform-training-activity-6769507240379461632-H_nw?utm_source=share&utm_medium=member_desktop',
        title: '30 minutes Terraform Training',
        desc: '30 minutes Terraform Training',
        commentCount: '3',
        btnText: 'Read More',
        metaList: [
            {
                iconName: 'icofont-ui-user',
                text: 'Atin Gupta',
            },
            {
                iconName: 'icofont-calendar',
                text: 'Aug 09,2022',
            },
        ],
    },
    {
        imgUrl: 'https://media.licdn.com/dms/image/C4D22AQHUtly-7vcc5w/feedshare-shrink_1280/0/1602079247922?e=1669852800&v=beta&t=AM73OM6VNYxKrg_KPVlpBTU0gmN8clnT71ND4k-O0r0',
        blog_url: 'https://www.linkedin.com/posts/profileatingupta_tcs-azure-activity-6719607400984268800-Ze5V?utm_source=share&utm_medium=member_desktop',
        title: 'To TCS, I delivered training on Azure in Oct 2020',
        desc: 'To TCS, I delivered training on Azure in Oct 2020 for 45 hours for below specific Topics - Data Lake, Data Factory, Synapse Analytics, Analysis Services, Key Vault, Active Direcory Powershell, Databricks, Logic Apps, Function App, DevOps',
        commentCount: '3',
        btnText: 'Read More',
        metaList: [
            {
                iconName: 'icofont-ui-user',
                text: 'Atin Gupta',
            },
            {
                iconName: 'icofont-calendar',
                text: 'Aug 09,2022',
            },
        ],
    },
    {
        imgUrl: 'https://media.licdn.com/dms/image/C4D22AQF8L8u7eScHkg/feedshare-shrink_800/0/1602909670955?e=1669852800&v=beta&t=MdXi2bUlMjA2OnystjEhG8h9znvb7P5Vg9oMojWfr2c',
        blog_url: 'https://www.linkedin.com/posts/profileatingupta_vmware-devops-terraform-activity-6723090448266612736-K11Z?utm_source=share&utm_medium=member_desktop',
        title: 'Delivered training to VMWare in Oct 2020 on Advanced DevOps - Terraform, Jenkins, Docker, Jfrog, Artifactory',
        desc: 'Delivered training to VMWare in Oct 2020 on Advanced DevOps - Terraform, Jenkins, Docker, Jfrog, Artifactory for 5 days',
        commentCount: '3',
        btnText: 'Read More',
        metaList: [
            {
                iconName: 'icofont-ui-user',
                text: 'Atin Gupta',
            },
            {
                iconName: 'icofont-calendar',
                text: 'Aug 09,2022',
            },
        ],
    },
    {
        imgUrl: 'https://media.licdn.com/dms/image/C5122AQHPRMZDLfSEXw/feedshare-shrink_800/0/1580356827563?e=1669852800&v=beta&t=IZJ833zNJ2dMpeqzr9rit6U_rd2_pkS-iI26Uw9tuRo',
        blog_url: 'https://www.linkedin.com/posts/profileatingupta_i-am-a-corporate-trainer-having-18-years-activity-6628496969801261056-ilEY?utm_source=share&utm_medium=member_desktop',
        title: 'Delivered training in Mercedes Bangalore',
        desc: 'Delivered training in Mercedes Bangalore',
        commentCount: '3',
        btnText: 'Read More',
        metaList: [
            {
                iconName: 'icofont-ui-user',
                text: 'Atin Gupta',
            },
            {
                iconName: 'icofont-calendar',
                text: 'Aug 09,2022',
            },
        ],
    },
    {
        imgUrl: 'https://media.licdn.com/dms/image/C4D22AQGHML5IL3YmMw/feedshare-shrink_800/0/1660897796499?e=1669852800&v=beta&t=E15pZHKDEcrgQOyE6V139C8DKY1dPJ5LuQqxHOmeQuU',
        blog_url: 'https://www.linkedin.com/posts/profileatingupta_providing-classroom-training-on-azure-to-activity-6966310276769984514-1xC5?utm_source=share&utm_medium=member_desktop',
        title: 'Delivered training on Azure to Harman, Bangalore',
        desc: 'Provided classroom training on Azure to Harman, Bangalore. Its a very good experience to stay here in Bangolore.',
        commentCount: '3',
        btnText: 'Read More',
        metaList: [
            {
                iconName: 'icofont-ui-user',
                text: 'Atin Gupta',
            },
            {
                iconName: 'icofont-calendar',
                text: 'Aug 09,2022',
            },
        ],
    },
    {
        imgUrl: 'https://media.licdn.com/dms/image/C4D22AQH4V0jMtANxAA/feedshare-shrink_1280/0/1663509897915?e=1669852800&v=beta&t=lsjAVc4_eB87lCt-THK3P2dCVRryLP0aRX3f-_3jgf4',
        blog_url: 'https://www.linkedin.com/posts/profileatingupta_delivered-training-on-devops-to-slk-in-bangalore-activity-6977266225865191424-kTcx?utm_source=share&utm_medium=member_desktop',
        title: 'Delivered training on DevOps to SLK in Bangalore',
        desc: 'Delivered training on DevOps to SLK in Bangalore. It was a great experience to work with Mr Amjad. All the participants were very good and learned a lot out of this 40 hours in depth training on DevOps',
        commentCount: '3',
        btnText: 'Read More',
        metaList: [
            {
                iconName: 'icofont-ui-user',
                text: 'Atin Gupta',
            },
            {
                iconName: 'icofont-calendar',
                text: 'Aug 09,2022',
            },
        ],
    },
    {
        imgUrl: 'https://media.licdn.com/dms/image/C5622AQG8xdk2kN4Dug/feedshare-shrink_800/0/1665750758949?e=1669852800&v=beta&t=bCyPJIUPmPI7ZYhl747pf_NdTHidquVoMAprPx8TIHk',
        blog_url: 'https://www.linkedin.com/posts/profileatingupta_returning-back-from-bangalore-to-delhi-after-activity-6986665079802933248-Uqkh?utm_source=share&utm_medium=member_desktop',
        title: 'Delivered training to Deloitte on Azure for 1 month in Oct 22 at Bangalore',
        desc: 'Delivered training to Deloitte on Azure for 1 month in Oct 22 as Bangalore. It was a long training and I delivered this long training for the first time. I also learned a lot in this training',
        commentCount: '1',
        btnText: 'Read More',
        metaList: [
            {
                iconName: 'icofont-ui-user',
                text: 'Atin Gupta',
            },
            {
                iconName: 'icofont-calendar',
                text: 'Oct 17,2022',
            },
        ],
    }
]



const BlogPage = (props) => {
    return ( 
        <Fragment>
            <Header currentUser={props.currentUser} setCurrentUser={props.setCurrentUser} userName={props.userName} setUserName={props.setUserName} email={props.email} setEmail={props.setEmail} isSignedIn={props.isSignedIn} setIsSignedIn={props.setIsSignedIn}  />
            <div className="blog-section padding-tb-less section-bg">
                <div className="container">
                    <div className="section-wrapper">
                        <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3 justify-content-center g-4">
                            {blogList.map((val, i) => (
                                <div className="col" key={i}>
                                    <div className="post-item">
                                        <div className="post-inner">
                                            <div className="post-thumb">
                                                <img src={`${val.imgUrl}`} alt={`${val.title}`} />
                                            </div>
                                            <div className="post-content">
                                                <h4>{val.title}</h4>
                                                <div className="meta-post">
                                                    <ul className="lab-ul">
                                                        {val.metaList.map((val, i) => (
                                                            <li key={i}><i className={val.iconName}></i>{val.text}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                                <p>{val.desc}</p>
                                            </div>
                                            <div className="post-footer">
                                                <div className="pf-left">
                                                    <a target='_blank' href={val.blog_url} className="lab-btn-text">{val.btnText} <i className="icofont-external-link"></i></a>
                                                </div>
                                                <div className="pf-right">
                                                    <i className="icofont-comment"></i>
                                                    <span className="comment-count">{val.commentCount}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <Pagination />
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}

export default BlogPage;