import YouTube, { YouTubeProps } from 'react-youtube';


const title = <h2><span>About</span> Atin Gupta</h2>;
const desc = "I am Sharing my knowledge with my lovely students";

const detailsList = [
    {
        leftText: 'Role',
        clone: ':',
        rightText: 'Microsoft Certified Corporate Trainer',
    },
    {
        leftText: 'Experience',
        clone: ':',
        rightText: '22 Years',
    },
    {
        leftText: 'Specialist in',
        clone: ':',
        rightText: 'Cloud - Azure, AWS, DevOps',
    },
    {
        leftText: '',
        clone: '',
        rightText: 'DevOps - GIT, Terraform, Jenkins, Ansible',
    },
    {
        leftText: '',
        clone: '',
        rightText: 'DevOps - Puppet, Docker, Kubernetes, Chef, Artifactory',
    },
    {
        leftText: '',
        clone: '',
        rightText: 'Azure ARC, Azure Synapse, Boto 3',
    },
    {
        leftText: '',
        clone: '',
        rightText: 'Azure Logic Apps, AWS Lambda, Azure Data Factory',
    },
    {
        leftText: '',
        clone: '',
        rightText: 'Blockchain, Ethereum, Hyperledger, Hyperledger Corda R3',
    },
    {
        leftText: '',
        clone: '',
        rightText: 'Machine Learning, Data Science',
    },
    {
        leftText: '',
        clone: '',
        rightText: 'Data Engineering, Spark, DataBricks, Neo4J, CosmosDB, Apache Cassandra',
    },
    {
        leftText: '',
        clone: '',
        rightText: 'Airflow, Power BI, NiFi',
    },
    {
        leftText: '',
        clone: '',
        rightText: 'Big Data, Hadoop, HDFS, Sqoop, YARN, HIVE',
    },
    {
        leftText: '',
        clone: '',
        rightText: 'Scripting, Python, R Programming, Scala, Groovy, Grails',
    },
    {
        leftText: 'Phone',
        clone: ':',
        rightText: '+919810707414',
    },
    {
        leftText: 'Mail',
        clone: ':',
        rightText: 'atingupta2005@gmail.com',
    },
    {
        leftText: 'Location :',
        clone: ':',
        rightText: 'Delhi, India',
    },
]

const countList = [
    {
        iconName: 'icofont-users-alt-4',
        count: '12600',
        desc: 'Students Enrolled',
    },
    {
        iconName: 'icofont-graduate-alt',
        count: '50',
        desc: 'Certified Trainer',
    },
    {
        iconName: 'icofont-notification',
        count: '350',
        desc: 'Professional Courses',
    },
]

const AboutThree = () => {
    const onPlayerReady: YouTubeProps['onReady'] = (event) => {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    }

    const opts: YouTubeProps['opts'] = {
    height: '390',
    width: '390',
    playerVars: {
        // https://developers.google.com/youtube/player_parameters
        enablejsapi: 1,
        origin: window.location.origin,
        host: 'https://www.youtube.com',
        loop: 1,
        autoplay: 0
    },
    };

    return (
        <section className="about-section style-4 padding-tb">
            <div className="container">
                <div className="about-items">
                    <div className="about-item">
                        <div className="image">
                            <YouTube videoId="BQggY0Bo0xc" opts={opts} onReady={onPlayerReady} />;
                        </div>
                    </div>
                    <div className="about-item">
                        <div className="about-content">
                            {title}
                            
                            <ul className="details lab-ul">
                                {detailsList.map((val, i) => (
                                    <li key={i}><p>{val.leftText} <span>{val.clone}</span></p>{val.rightText}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
 
export default AboutThree;