
import { Link } from "react-router-dom";
import Rating from "../sidebar/rating";

const subTitle = "Learn easily the Bright Thing";
const title = "Our Publications";


const courseList = [
    {
        imgUrl: 'assets/images/course/linear-regression.png',
        imgAlt: 'Linear Regression in R',
        title: 'Linear Regression in R',
        cate: 'MOOC',
        oldPrice: '$199.00',
        newPrice: '$150.00',
        url: 'https://www.youtube.com/playlist?list=PLPOcVTCXqBGbIHzwXgyc_wjk63_1uXm3l',
    },
    {
        imgUrl: 'assets/images/course/python-git.png',
        imgAlt: 'Python and GIT Training',
        title: 'Python and GIT Training',
        cate: 'Python and Git Training',
        oldPrice: '$199.00',
        newPrice: '$150.00',
        url: 'https://www.youtube.com/playlist?list=PLPOcVTCXqBGY5wBCfM6nv7vLOnowjvkRv',
    },
]

const CourseSix = () => {
    return (
        <div className="course-section style-4 padding-tb">
            <div className="container">
                <div className="section-header text-center">
                    <span className="subtitle">{subTitle}</span>
                    <h2 className="title">{title}</h2>
                </div>
                <div className="section-wrapper">
                    <div className="row g-4 justify-content-center row-cols-xl-4 row-cols-lg-3 row-cols-md-2 row-cols-1">
                        {courseList.map((val, i) => (
                            <div className="col" key={i}>
                                <div className="course-item style-5">
                                    <div className="course-inner">
                                        <div className="course-thumb">
                                            <a target="_blank" href={`${val.url}`} className="d-block"><img className="publication-image" src={`${val.imgUrl}`} alt={`${val.imgAlt}`} /></a>
                                        </div>
                                        <div className="course-content">
                                            <a target="_blank" href={`${val.url}`}><h5>{val.title}</h5></a>
                                            <span className="course-cate">{val.cate}</span>
                                            <div className="course-footer">
                                                <Rating />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default CourseSix;