import { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import GoogleAuth from '../../page/google-login';

const HeaderFive = (props) => {
    const [menuToggle, setMenuToggle] = useState(false);
	const [socialToggle, setSocialToggle] = useState(false);
	const [headerFiexd, setHeaderFiexd] = useState(false);
    const [imageUrl, setImageUrl] = useState("#");

    useEffect(() => {
    if (props.currentUser && props.currentUser.getBasicProfile())
    {
        //console.log("props.currentUser: ", props.currentUser.getBasicProfile().getImageUrl())
        setImageUrl(props.currentUser.getBasicProfile().getImageUrl())
    }
    else {
        setImageUrl("")
    }
    });
    
	window.addEventListener("scroll", () => {
		if (window.scrollY > 200) {
			setHeaderFiexd(true);
		} else {
			setHeaderFiexd(false);
		}
	});
    //console.log("header props: ", props)
    
    return (
        <header className={`header-section style-3 style-5 ${headerFiexd ? "header-fixed fadeInUp" : ""}`}>
            <div className={`header-top d-md-none ${socialToggle ? "open" : ""}`}>
                <div className="container">
                    <div className="header-top-area">
                        <Link to="/signup" className="lab-btn me-3"><span>Sign In2</span></Link>
                        <Link to="/login">Log In</Link>
                    </div>
                </div>
            </div>
            <div className="header-bottom">
                <div className="container">
                    <img referrerPolicy="no-referrer" className='loginGreetings'src={imageUrl} />
                    <div className="header-wrapper">
                        <div className="logo-search-acte">
                            <div className="logo">
                                <Link to="/"><img src="assets/images/logo/01.png" alt="logo" /></Link>
                            </div>
                        </div>
                        <div className="menu-area">
                            <div className="menu">
                                <ul className={`lab-ul ${menuToggle ? "active" : ""}`}>
                                    <li><NavLink to="/">Home</NavLink></li>
                                    <li><NavLink to="/course">Courses</NavLink></li>
                                    <li><NavLink to="/training-requirements">Requirements</NavLink></li>
                                    <li><NavLink to="/blog">Blog</NavLink></li>
                                    <li><NavLink to="/contact">Contact</NavLink></li>
                                    <li><NavLink to="#"><GoogleAuth currentUser={props.currentUser} setCurrentUser={props.setCurrentUser} userName={props.userName} setUserName={props.setUserName} email={props.email} setEmail={props.setEmail} isSignedIn={props.isSignedIn} setIsSignedIn={props.setIsSignedIn} /></NavLink></li>
                                </ul>
                            </div>
                            
                            

                            <div className={`header-bar d-lg-none ${menuToggle ? "active" : "" }`} onClick={() => setMenuToggle(!menuToggle)}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="ellepsis-bar d-md-none" onClick={() => setSocialToggle(!socialToggle)}>
                                <i className="icofont-info-square"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}
 
export default HeaderFive;