import { Component, Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeaderTwo from "../component/layout/pageheader-2";
import Author from "../component/sidebar/author";
import Comment from "../component/sidebar/comment";
import CourseSideCetagory from "../component/sidebar/course-cetagory";
import CourseSideDetail from "../component/sidebar/course-detail";
import Respond from "../component/sidebar/respond";


const CourseAzureAdmin = () => {
    return (
        <Fragment>
            <Header />
            <div className="course-single-section padding-tb-very-less section-bg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="main-part">
                                <div className="course-item">
                                    <div className="course-inner">
                                        <div className="course-content">
                                            <h3>Course Overview</h3>
                                            <p>In this course you will learn Azure Administration</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="course-video">
                                    <div className="course-video-title">
                                        <h4>Course Content</h4>
                                    </div>
                                    <div className="course-video-content">
                                        <div className="accordion" id="accordionExample">
                                            <div className="accordion-item">
                                                <div className="accordion-header" id="accordion01">
                                                    <button className="d-flex flex-wrap justify-content-between" data-bs-toggle="collapse" data-bs-target="#videolist1" aria-expanded="true" aria-controls="videolist1"><span>Azure Administration</span> <span></span> </button>
                                                </div>
                                                <div id="videolist1" className="accordion-collapse collapse show" aria-labelledby="accordion01" data-bs-parent="#accordionExample">
                                                    <ul className="lab-ul video-item-list">
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                        <a href="tutorials/1-login-to-azure-portal/index.html" className="popup" target="_blank">
                                                            
                                                                <div className="video-item-title">Login to Azure Portal</div>
                                                            
                                                        </a>
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                        <a href="tutorials/2-create-resource-group/index.html" className="popup" target="_blank">
                                                            
                                                                <div className="video-item-title">Create Resource Group</div>
                                                            
                                                        </a>
                                                        </li>

                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                        <a href="tutorials/3-create-windows-11-vm/index.html" className="popup" target="_blank">
                                                            
                                                                <div className="video-item-title">Create Windows Virtual Machine</div>
                                                            
                                                        </a>
                                                        </li>
                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                        <a href="tutorials/4-connect-to-windows-11-vm/index.html" className="popup" target="_blank">
                                                            
                                                                <div className="video-item-title">Connect to Windows Virtual Machine</div>
                                                            
                                                        </a>
                                                        </li>

                                                        <li className=" d-flex flex-wrap justify-content-between">
                                                        <a href="tutorials/5-create-linux-ubuntu-20-vm/index.html" className="popup" target="_blank">
                                                            
                                                                <div className="video-item-title">Create Ubuntu Virtual Machine</div>
                                                            
                                                        </a>
                                                        </li>

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Author />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="sidebar-part">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}

export default CourseAzureAdmin;