
import { Link } from "react-router-dom";

const subTitle = "";
const title = <h2 className="title"><span>I am</span> Atin Gupta</h2>;
const desc = "Lead Trainer at ATT Trainings";
const btnText = "Our Courses";



const BannerFive = () => {
    return (
        <section className="banner-section style-5 padding-tb">
            <div className="container">
                <div className="section-wrapper">
                    <div className="row">
                        <div className="offset-md-6 col-xl-6">
                            <div className="banner-content"> 
                                <h4 className="subtitle">{subTitle}</h4>
                                {title}
                                <p className="desc">{desc}</p>
                                <Link to="/course" className="lab-btn"><span>{btnText}</span></Link>
                                <a href="https://www.atingupta.in" className="lab-btn" target="_blank"><span>Atin's Portfolio</span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}


//export default BannerFive;

const BannerFive_2 = () => {
    return ("")
}

export default BannerFive_2;