import { Component, Fragment, useState, useRef, useEffect } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import { useForm } from "react-hook-form";
import PageHeader from "../component/layout/pageheader";
import GoogleMap from "../component/sidebar/googlemap";
import ReCAPTCHA from 'react-google-recaptcha';
import { useLocation } from "react-router-dom";
import axios from "axios";

const subTitle = "Get in touch with us";
const title = "We're Always Eager To Hear From You!";
const conSubTitle = "Get in touch with Contact us";
const conTitle = "Fill The Form Below So We Can Get To Know You And Your Needs Better.";
const btnText = "Enroll";


const contactList = [
    {
        imgUrl: 'assets/images/icon/01.png',
        imgAlt: 'contact icon',
        title: 'Office Address',
        desc: 'Delhi NCR',
    },
    {
        imgUrl: 'assets/images/icon/02.png',
        imgAlt: 'contact icon',
        title: 'Phone number',
        desc: '+919810707414',
    },
    {
        imgUrl: 'assets/images/icon/03.png',
        imgAlt: 'contact icon',
        title: 'Send email',
        desc: 'atingupta2005@gmail.com',
    },
    {
        imgUrl: 'assets/images/icon/04.png',
        imgAlt: 'contact icon',
        title: 'Our website',
        desc: 'www.atttrainings.com',
    },
]


const CourseEnroll = () => {
    const [successMessage, setSuccessMessage] = useState("");
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const [userName, setUserName] = useState("");
	const [email, setEmail] = useState("");

    const search = useLocation().search;
    const title = new URLSearchParams(search).get('title');

    useEffect(() => {
				if (window.gapi.auth2 && window.gapi.auth2.getAuthInstance().isSignedIn.get())
				{
					setEmail(window.gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getEmail());
					setUserName(window.gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getName());
				}
    });

    const subject_text = "Enroll for " + title
    const body_text = "Dear Team,\n\nPlease enroll me for the course - " + title + ".\n\nI confirm that mobile number is correct\n\nThanks,\n" + userName


    const recaptchaRef = useRef(null);
    
    const onSubmit = (data, r) => {
        //console.log("Starting onSubmit");
        //console.log(recaptchaRef);
        //console.log(data);
        //console.log(r);

        const recaptchaValue = recaptchaRef.current.value;
        
        //console.log(recaptchaValue);

        sendEmail(
        {
            name: userName,
            phone: data.phone,
            email: email,
            subject: data.subject,
            description: data.description,
            form_name: "course-enrollment",
            course_title: title,
            'g-recaptcha-response': recaptchaValue,
        }
        )
        r.target.reset();
    }

    const sendEmail = (variables) => {
        const baseURL = `${process.env.REACT_APP_BASE_URL}/lead-generation/sendemailcommon/`

        axios.post(baseURL, variables).then((response) => {
            //console.log(response.data);
            setSuccessMessage(response.data);
        });
    }

    const onChange = (value) => {
        recaptchaRef.current.value = value;
        //console.log('Captcha value:', value);
        //console.log('recaptchaRef:', recaptchaRef);
    }
    return ( 
        <Fragment>
            <Header />
            <div className="map-address-section">
                <div className="container">
                    <div className="section-wrapper">
                        <div className="row flex-row-reverse">
                            <div className="col-xl-4 col-lg-5 col-12">
                                <div className="contact-wrapper padding-tb-less">
                                    {contactList.map((val, i) => (
                                        <div className="contact-item" key={i}>
                                            <div className="contact-thumb">
                                                <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                            </div>
                                            <div className="contact-content">
                                                <h6 className="title">{val.title}</h6>
                                                <p>{val.desc}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
        <div className="col-xl-8 col-lg-7 col-12">
            <div className="contact-section padding-tb-less">
                <span className="success-message">{successMessage}</span>
                <div className="container">
                    <div className="section-wrapper">
                        <form method="post" className="contact-form" onSubmit={handleSubmit(onSubmit)}>
                            <p>Enrollment for the course - <b>{title}</b></p>
                            <span className="error-message">
                                {errors.phone && errors.phone.message}
                            </span>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="phone"
                                    placeholder="Mobile Number *"
                                    {
                                        ...register("phone", {
                                        required: "Please enter correct 10 digit mobile number",
                                        maxLength: 10,
                                        minLength: 10
                                        }
                                        )
                                    }
                                    aria-invalid={errors.phone ? "true" : "false"} 
                                />
                            </div>

                            <span className="error-message">
                                {errors.subject && errors.subject.message}
                            </span>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="subject"
                                    defaultValue={subject_text}
                                    placeholder="Your Subject *"
                                    {
                                        ...register("subject", {
                                        required: "Please enter your subject",
                                        maxLength: 50,
                                        minLength: 3
                                        }
                                        )
                                    }
                                    aria-invalid={errors.subject ? "true" : "false"} 
                                />
                            </div>

                            <span className="error-message">
                                {errors.description && errors.description.message}
                            </span>
                            <div className="form-group w-100">
                                <textarea 
                                    rows="25"
                                    type="text"
                                    defaultValue={body_text}
                                    name="description"
                                    placeholder="Your Message"
                                    {
                                        ...register("description", {
                                        required: "Please enter your description",
                                        maxLength: 500,
                                        minLength: 3
                                        }
                                        )
                                    }
                                    aria-invalid={errors.description ? "true" : "false"} 
                                ></textarea>
                            </div>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey="6LecE1giAAAAAApN2obCkit1Soc8jGIF960Sgwqh"
                                onChange={onChange}
                            />
                            <div className="form-group w-100 text-center">
                                <button className="lab-btn" type="submit"><span>{btnText}</span></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </Fragment>
    );
}



export default CourseEnroll;