
import { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import Rating from "../sidebar/rating";
import EnrollNow from '../../page/enroll-now';
import GoogleAuth from '../../page/google-login';

const subTitle = "Don’t Miss";
const title = "Our Courses";

const courseList = [
    {
        imgUrl: 'assets/images/course/azure.png',
        imgAlt: 'Azure Administration',
        courseName: 'Azure Cloud',
        coursePrice: '$100',
        courseTitle: 'Azure Administration',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
    },
    {
        imgUrl: 'assets/images/course/azure-devops.png',
        imgAlt: 'Azure DevOps',
        courseName: 'Azure DevOps',
        coursePrice: '$100',
        courseTitle: 'Azure DevOps',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
    }
    ,
    {
        imgUrl: 'assets/images/course/data-engineering.png',
        imgAlt: 'Azure Data Engineer',
        courseName: 'Azure Data Engineer',
        coursePrice: '$100',
        courseTitle: 'Azure Data Engineer',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
    }
    ,
    {
        imgUrl: 'assets/images/course/azure-architect.png',
        imgAlt: 'Azure Architect',
        courseName: 'Azure Architect',
        coursePrice: '$100',
        courseTitle: 'Azure Architect',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
    }
    ,
    {
        imgUrl: 'assets/images/course/azure-security.png',
        imgAlt: 'Azure Security',
        courseName: 'Azure Security',
        coursePrice: '$100',
        courseTitle: 'Azure Security',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
    },
    {
        imgUrl: 'assets/images/course/azure-machine-learning.png',
        imgAlt: 'Azure Machine Learning',
        courseName: 'Azure Machine Learning',
        coursePrice: '$100',
        courseTitle: 'Azure Machine Learning',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
    },
    {
        imgUrl: 'assets/images/course/azure-ai.png',
        imgAlt: 'Azure AI',
        courseName: 'Azure AI',
        coursePrice: '$100',
        courseTitle: 'Azure AI',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
    },
    {
        imgUrl: 'assets/images/course/blockchain-business.png',
        imgAlt: 'Blockchain Foundations',
        courseName: 'English',
        coursePrice: '$100',
        courseTitle: 'Blockchain Foundations',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
    },
    {
        imgUrl: 'assets/images/course/blockchain-solution-architect.png',
        imgAlt: 'Blockchain Solution Architect',
        courseName: 'Blockchain Solution Architect',
        coursePrice: '$100',
        courseTitle: 'Blockchain Solution Architect',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
    },
    {
        imgUrl: 'assets/images/course/blockchain-developer.png',
        imgAlt: 'Blockchain  Developer',
        courseName: 'Blockchain Developer',
        coursePrice: '$100',
        courseTitle: 'Blockchain  Developer',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
    },
    {
        imgUrl: 'assets/images/course/terraform.png',
        imgAlt: 'Terraform',
        courseName: 'Terraform',
        coursePrice: '$100',
        courseTitle: 'Terraform',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
    },
    {
        imgUrl: 'assets/images/course/python.png',
        imgAlt: 'Python',
        courseName: 'Python',
        coursePrice: '$100',
        courseTitle: 'Python',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
    },
    {
        imgUrl: 'assets/images/course/machine-learning.png',
        imgAlt: 'Machine Learning',
        courseName: 'Machine Learning',
        coursePrice: '$100',
        courseTitle: 'Machine Learning',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
    }
    ,
    {
        imgUrl: 'assets/images/course/R-Programming.png',
        imgAlt: 'R Programming',
        courseName: 'R Programming',
        coursePrice: '$100',
        courseTitle: 'R Programming',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
    }
    ,
    {
        imgUrl: 'assets/images/course/data-science.png',
        imgAlt: 'Data Science',
        courseName: 'Data Science',
        coursePrice: '$100',
        courseTitle: 'Data Science',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
    }
    ,
    {
        imgUrl: 'assets/images/course/big-data-hadoop.png',
        imgAlt: 'Big Data and Hadoop',
        courseName: 'Big Data and Hadoop',
        coursePrice: '$100',
        courseTitle: 'Big Data and Hadoop',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
    }
    ,
    {
        imgUrl: 'assets/images/course/git.png',
        imgAlt: 'GIT',
        courseName: 'GIT',
        coursePrice: '$100',
        courseTitle: 'GIT',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
    }
    ,
    {
        imgUrl: 'assets/images/course/apache-spark.png',
        imgAlt: 'Apache Spark / PySpark',
        courseName: 'Apache Spark / PySpark',
        coursePrice: '$100',
        courseTitle: 'Apache Spark / PySpark',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
    }
    ,
    {
        imgUrl: 'assets/images/course/scala.png',
        imgAlt: 'Scala',
        courseName: 'Scala',
        coursePrice: '$100',
        courseTitle: 'Scala',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
    }
    ,
    {
        imgUrl: 'assets/images/course/databricks.png',
        imgAlt: 'Data Bricks',
        courseName: 'Data Bricks',
        coursePrice: '$100',
        courseTitle: 'Data Bricks',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
    }
    ,
    {
        imgUrl: 'assets/images/course/docker.png',
        imgAlt: 'Docker',
        courseName: 'Docker',
        coursePrice: '$100',
        courseTitle: 'Docker',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
    },
    {
        imgUrl: 'assets/images/course/kubernetes.png',
        imgAlt: 'Kubernetes',
        courseName: 'Kubernetes',
        coursePrice: '$100',
        courseTitle: 'Kubernetes',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
    },
    {
        imgUrl: 'assets/images/course/artifactory.png',
        imgAlt: 'Artifactory',
        courseName: 'Artifactory',
        coursePrice: '$100',
        courseTitle: 'Artifactory',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
    },
    {
        imgUrl: 'assets/images/course/chef.png',
        imgAlt: 'Chef',
        courseName: 'Chef',
        coursePrice: '$100',
        courseTitle: 'Chef',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
    },
    {
        imgUrl: 'assets/images/course/puppet.png',
        imgAlt: 'Puppet',
        courseName: 'Puppet',
        coursePrice: '$100',
        courseTitle: 'Puppet',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
    },
    {
        imgUrl: 'assets/images/course/ansible.png',
        imgAlt: 'Ansible',
        courseName: 'Ansible',
        coursePrice: '$100',
        courseTitle: 'Ansible',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
    },
    {
        imgUrl: 'assets/images/course/jenkins.png',
        imgAlt: 'Jenkins',
        courseName: 'Jenkins',
        coursePrice: '$100',
        courseTitle: 'Jenkins',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
    },
    {
        imgUrl: 'assets/images/course/terraform.png',
        imgAlt: 'Terraform',
        courseName: 'Terraform',
        coursePrice: '$100',
        courseTitle: 'Terraform',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
    },
    {
        imgUrl: 'assets/images/course/neo4j.png',
        imgAlt: 'Neo4J',
        courseName: 'Neo4J',
        coursePrice: '$100',
        courseTitle: 'Neo4J',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
    },
    {
        imgUrl: 'assets/images/course/cosmosdb.png',
        imgAlt: 'CosmosDB',
        courseName: 'CosmosDB',
        coursePrice: '$100',
        courseTitle: 'CosmosDB',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
    },
    {
        imgUrl: 'assets/images/course/cassandra.png',
        imgAlt: 'Apache Cassandra',
        courseName: 'Apache Cassandra',
        coursePrice: '$100',
        courseTitle: 'Apache Cassandra',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
    },
    {
        imgUrl: 'assets/images/course/airflow.png',
        imgAlt: 'Airflow',
        courseName: 'Airflow',
        coursePrice: '$100',
        courseTitle: 'Airflow',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
    },
    {
        imgUrl: 'assets/images/course/azure-data-factory.png',
        imgAlt: 'Azure Data Factory',
        courseName: 'Azure Data Factory',
        coursePrice: '$100',
        courseTitle: 'Azure Data Factory',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
    },
    {
        imgUrl: 'assets/images/course/power-bi.png',
        imgAlt: 'Power BI',
        courseName: 'Power BI',
        coursePrice: '$100',
        courseTitle: 'Power BI',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
    },
    {
        imgUrl: 'assets/images/course/groovy-and-grails.png',
        imgAlt: 'Groovy and Grails',
        courseName: 'Groovy and Grails',
        coursePrice: '$100',
        courseTitle: 'Groovy and Grails',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
    },
    {
        imgUrl: 'assets/images/course/apache-nifi.png',
        imgAlt: 'Apache NiFi',
        courseName: 'Apache NiFi',
        coursePrice: '$100',
        courseTitle: 'Apache NiFi',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
    },
    {
        imgUrl: 'assets/images/course/splunk.png',
        imgAlt: 'Splunk',
        courseName: 'Splunk',
        coursePrice: '$100',
        courseTitle: 'Splunk',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
    },
    {
        imgUrl: 'assets/images/course/datadog.png',
        imgAlt: 'DataDog',
        courseName: 'DataDog',
        coursePrice: '$100',
        courseTitle: 'DataDog',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
    },
    {
        imgUrl: 'assets/images/course/appdynamics.png',
        imgAlt: 'AppDynamics',
        courseName: 'AppDynamics',
        coursePrice: '$100',
        courseTitle: 'AppDynamics',
        user: '200',
        commentCount: '23',
        btnText: 'Enroll Now',
    },
    
    
    
]


const CourseFive = (props) => {
    return (
        <div className="course-section style-2 padding-tb section-bg-ash yellow-color-section">
            <div className="container">
                <div className="section-header">
                    <span className="subtitle">{subTitle}</span>
                    <h2 className="title">{title}</h2>
                    <div className="course-navigations">
                        <div className="course-navi course-navi-next"><i className="icofont-double-left"></i></div>
                        <div className="course-navi course-navi-prev"><i className="icofont-double-right"></i></div>
                    </div>
                </div>
                <div className="section-wrapper">
                    <div className="course-slider p-2">
                        <div className="swiper-wrapper">
                            <Swiper
                                spaceBetween={20}
                                slidesPerView={2}
                                loop={'true'}
                                autoplay={{
                                    delay: 5000,
                                    disableOnInteraction: false,
                                }}
                                navigation={{
                                    prevEl: '.course-navi-prev',
                                    nextEl: '.course-navi-next',
                                }}
                                modules={[Autoplay, Navigation]}
                                breakpoints={{
                                    0: {
                                        width: 0,
                                        slidesPerView: 1,
                                    },
                                    768: {
                                        width: 768,
                                        slidesPerView: 2,
                                    },
                                    1200: {
                                        width: 1200,
                                        slidesPerView: 2.85,
                                    },
                                }}
                            >
                                {courseList.map((val, i) => (
                                    <SwiperSlide key={i}>
                                        <div className="course-item style-3">
                                            <div className="course-inner text-center">
                                                <div className="course-thumb">
                                                    <img className="course-image" src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                </div>
                                                <div className="course-content">
                                                    <a href="course-enroll"><h4>{val.courseTitle}</h4></a>
                                                    <div className="course-details">
                                                    </div>
                                                    <div className="lab-btn">
                                                        {props.isSignedIn ? (
                                                            <EnrollNow userName={props.userName} email={props.email} title={val.courseTitle}/>
                                                        ) : (<a><GoogleAuth btn_label="Enroll Now" currentUser={props.currentUser} setCurrentUser={props.setCurrentUser} userName={props.userName} setUserName={props.setUserName} email={props.email} setEmail={props.setEmail} isSignedIn={props.isSignedIn} setIsSignedIn={props.setIsSignedIn} /></a>) }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default CourseFive;