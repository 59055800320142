
import { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';


const subTitle = "Testimonials";
const title = "What People Say";
const absTitle = "Testimonials";


const clientSliderList = [
    {
        imgUrl: 'https://media-exp1.licdn.com/dms/image/C4D03AQFyZNYIw1G1Jw/profile-displayphoto-shrink_800_800/0/1627836464882?e=1669248000&v=beta&t=O90kBOAvcMcy91CzUxCea1-1MAEeWNgB6uqXjdofLrw',
        imgAlt: 'technically sound',
        iconNane: 'icofont-quote-left',
        desc: 'He is very technically sound and believe to keep the team unite to achieve the organization goals. A good technical knowledge with good team manager',
        name: 'Vikas Kumar Verma',
        degi: 'Sr. Technical Program Manager',
    },
    {
        imgUrl: 'https://media-exp1.licdn.com/dms/image/C4E03AQG6Lb3H2fhrHQ/profile-displayphoto-shrink_800_800/0/1517697703033?e=1669248000&v=beta&t=_o0BC9LgvRg3zV4iKxfFlXrUt8TIl2fimvvYGBzpO-c',
        imgAlt: 'completed all tasks on time',
        iconNane: 'icofont-quote-left',
        desc: 'I worked with Atin on several projects. Atin completed all tasks on time. He provided quality work. I really enjoy working with Atin. He is very honest, intelligent, and professional. I would highly recommend Atin.',
        name: 'Tatiana Kolina',
        degi: 'Founder of sPACYcLOUd Founder at Skate Girls Tribe',
    },
    {
        imgUrl: 'https://media-exp1.licdn.com/dms/image/C4E03AQHBOZtVvbj00Q/profile-displayphoto-shrink_800_800/0/1639855324153?e=1669248000&v=beta&t=ynr6qsqVdwKu77Th9LRNjGmnAe2mfRE7BGz8H6gGjkw',
        imgAlt: 'technically sound',
        iconNane: 'icofont-quote-left',
        desc: 'My first contact with “Atin Gupta” was to invite him to consult about Project for a renowned, internationally recognized , client for a Facebook API . Where “Atin Gupta” grasped attention and helped complete projects with his best technical knowledge. That was not the only one occation ,he saved our projects but also he helped in many other technical project with his leadership skills and mentorship. Me and My clients are highly impressed with his technical and softskills. Now He is best choice for my international clients',
        name: 'Sunil Pratap Singh',
        degi: 'Facebook Ads Marketing & Google Ads Expert & Consultant ',
    },
    {
        imgUrl: 'https://media-exp1.licdn.com/dms/image/C4E03AQG6Lb3H2fhrHQ/profile-displayphoto-shrink_800_800/0/1517697703033?e=1669248000&v=beta&t=_o0BC9LgvRg3zV4iKxfFlXrUt8TIl2fimvvYGBzpO-c',
        imgAlt: 'completed all tasks on time',
        iconNane: 'icofont-quote-left',
        desc: 'Atin has worked on several projects for me. He is knowledgeable in many technologies including php, css, xpages, java, and domino. He is proficient in other technologies as well. He is very professional and completes projects on time. He is very pleasant to work with. I highly recommend Atin and his team',
        name: 'Tatiana Kolina',
        degi: 'Founder of sPACYcLOUd Founder at Skate Girls Tribe',
    },
    {
        imgUrl: 'https://media-exp1.licdn.com/dms/image/C5603AQE63d3kctvLxA/profile-displayphoto-shrink_800_800/0/1516497709538?e=1669248000&v=beta&t=LPhdxTKgNu3wo0Ib6p2sV0uwqUOodcfbQbmpDVJlMq8',
        imgAlt: 'technically sound',
        iconNane: 'icofont-quote-left',
        desc: 'But Attin gupta is that much of sound knowledge inlotus Domino, just he knows Apps integration and deployment in lotus servers , But he also practicing web apps and attending interview to ITC, IBM, and Wipro. I know him very well , just he is active but not sharp.',
        name: 'rao j',
        degi: 'Business Head',
    },
    {
        imgUrl: 'https://media-exp1.licdn.com/dms/image/C4E03AQHap77KVIURrw/profile-displayphoto-shrink_800_800/0/1620980583360?e=1669248000&v=beta&t=kK254XW3nYeAuT7ZUmYa0w4byClUuzITOxGYwx7VRGQ',
        imgAlt: 'technically sound',
        iconNane: 'icofont-quote-left',
        desc: 'Atin is very much strong in technical field. He is very good in Lotus Notes development. Along with this he is a fast learner and hard working.',
        name: 'Pranav Kumar, PMP',
        degi: 'IT Software Industry Specialist | Program/Project Management | Architecture | Pre-sales solutions',
    },
    {
        imgUrl: 'https://media-exp1.licdn.com/dms/image/C4D03AQFW1ct_YP7uFg/profile-displayphoto-shrink_800_800/0/1613068199534?e=1669248000&v=beta&t=Axg8zhr0NY49-izeQ1fSrvr0VSF3-Ujntq3r9e3UOyU',
        imgAlt: 'technically sound',
        iconNane: 'icofont-quote-left',
        desc: 'Atin....a very sincere and aggressive person when it comes to work. And one of the most lovable, helpful and dear person I have known. Good with technical knowledge and very much dedicated to the work assigned. Also a fun loving individual. Not a gossiper :) but loves to preach good lessons abt life and the cures at times ;). Atin I wish u all the very best and I am glad we crossed path. Stay in touch. Cheers!!',
        name: 'Vinita Sharma',
        degi: 'Business Consultant-Digital Marketing, Social Media, Bookkeeping- QuickBooks and Virtual Assistant',
    },
    {
        imgUrl: 'https://media-exp1.licdn.com/dms/image/C5603AQHthriCG-1w-g/profile-displayphoto-shrink_800_800/0/1517767296189?e=1669248000&v=beta&t=tcWMLPzr-rPmQRQIdtEx91OEdwK0AQayk9hmdUPkWPk',
        imgAlt: 'technically sound',
        iconNane: 'icofont-quote-left',
        desc: 'Atin is a dedicated worker.',
        name: 'Nishi (Bhatnagar) Jha',
        degi: 'Manager - Recruitment and Development',
    },
    {
        imgUrl: 'https://media-exp1.licdn.com/dms/image/C5603AQHthriCG-1w-g/profile-displayphoto-shrink_800_800/0/1517767296189?e=1669248000&v=beta&t=tcWMLPzr-rPmQRQIdtEx91OEdwK0AQayk9hmdUPkWPk',
        imgAlt: 'technically sound',
        iconNane: 'icofont-quote-left',
        desc: 'Attin worked me on a project delivery. It really good to see him interact with customers while we were finalizing application scope and dealing with change requests. He would steer the discussion to help customer choose write approach while protecting our interests. He was a newly appointed team leader but his people management skills helped him gain teams support and deliver project in time. Very good resource in any team.',
        name: 'Nishi (Bhatnagar) Jha',
        degi: 'Manager - Recruitment and Development',
    },
    {
        imgUrl: 'https://media-exp1.licdn.com/dms/image/C4E03AQFVwRS-R4iKjQ/profile-displayphoto-shrink_800_800/0/1522308454114?e=1669248000&v=beta&t=SfzBzT6K9s3cYfsjysxdfLki5E-CCJ6acqwGdalP1zY',
        imgAlt: 'technically sound',
        iconNane: 'icofont-quote-left',
        desc: 'Atin is a dedicated worker.',
        name: 'Suraj Gurav',
        degi: 'Specialist - Business Applications @ Microsoft',
    },
    {
        imgUrl: 'https://media-exp1.licdn.com/dms/image/C5603AQEd9Y0LlYODQg/profile-displayphoto-shrink_800_800/0/1615262281189?e=1669248000&v=beta&t=vXpg9ZlU-MXCP8jdxHd8wlvg1ZJk3nHbxq5A_oMP0Z4',
        imgAlt: 'technically sound',
        iconNane: 'icofont-quote-left',
        desc: 'Atin is very serious when it comes to work and even more serious when it comes to fun. He is very methodical in his work and ensures that each and every process is appropriately followed. He is quite innovative and always takes initiatives to streamline and improvise processes. He is always there to listen to you and ofcourse help out with literally anything be it technical or functional; he has a solution for everything. His positive attitude toward work, high energy levels and great leadership skills make him a very valuable asset for any organization."',
        name: 'Dr. Vijay S Jha, PhD',
        degi: 'AWS Certified Solution Architect Professional, product engineering and R&D, cloud migration, cloud security, data analytics and product transformation',
    },
    {
        imgUrl: 'https://media-exp1.licdn.com/dms/image/C5603AQHthriCG-1w-g/profile-displayphoto-shrink_800_800/0/1517767296189?e=1669248000&v=beta&t=tcWMLPzr-rPmQRQIdtEx91OEdwK0AQayk9hmdUPkWPk',
        imgAlt: 'technically sound',
        iconNane: 'icofont-quote-left',
        desc: 'Atin is a dedicated worker.',
        name: 'Nishi (Bhatnagar) Jha',
        degi: 'Manager - Recruitment and Development',
    },
    {
        imgUrl: 'https://media-exp1.licdn.com/dms/image/C5603AQHthriCG-1w-g/profile-displayphoto-shrink_800_800/0/1517767296189?e=1669248000&v=beta&t=tcWMLPzr-rPmQRQIdtEx91OEdwK0AQayk9hmdUPkWPk',
        imgAlt: 'technically sound',
        iconNane: 'icofont-quote-left',
        desc: 'Atin is a dedicated worker.',
        name: 'Nishi (Bhatnagar) Jha',
        degi: 'Manager - Recruitment and Development',
    },

]


const ClientsThree = () => {
    return (
        <div className="clients-section style-3 padding-tb">
            <div className="container">
                <div className="section-header text-center">
                    <span className="subtitle yellow-color">{subTitle}</span>
                    <h2 className="title">{title}</h2>
                    <h2 className="abs-title">{absTitle}</h2>
                </div>
                <div className="section-wrapper">
                    <div className="clients-slider2 overflow-hidden">
                        <Swiper
                            loop={'true'}
                            autoplay={{
                                delay: 5000,
                                disableOnInteraction: false,
                            }}
                            navigation={{
                                prevEl: '.clients-slider2-prev',
                                nextEl: '.clients-slider2-next',
                            }}
                            modules={[Autoplay, Navigation]}
                        >
                            {clientSliderList.map((val, i) => (
                                <SwiperSlide key={i}>
                                    <div className="client-item">
                                        <div className="client-inner">
                                            <div className="client-thumb">
                                                <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                <div className="quote">
                                                    <i className={val.iconNane}></i>
                                                </div>
                                            </div>
                                            <div className="client-content">
                                                <p>{val.desc}</p>
                                                <div className="client-info">
                                                    <h6 className="client-name">{val.name}</h6>
                                                    <span className="client-degi">{val.degi}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <div className="clients-slider-nav clients-slider2-next"><i className="icofont-double-left"></i></div>
                        <div className="clients-slider-nav clients-slider2-prev"><i className="icofont-double-right"></i></div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default ClientsThree;